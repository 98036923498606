<template>
  <section id="download-app-instructions" class="pb-28 pt-16 lg-download">
    <div class="container lg:flex lg:flex-row lg:justify-center">
      <div class="qrcode-app hidden lg:block">
        <QRCodeViewer />
      </div>
      <div class="app-text w-full lg:w-auto lg:ml-19">
        <div class="download-text">
          <h3 class="text-blueOtto-100 text-center mb-8 lg:mb-5 lg:text-left">
            Baixe nosso <br />
            aplicativo e crie sua conta <br />
            gratuitamente!
          </h3>

          <p class="text-center font-medium leading-8 mb-10 lg:hidden">
            Clique em um dos botões abaixo e baixe <br />
            nosso aplicativo. Depois, siga as instruções para <br />
            abrir sua conta. É fácil e rápido.
          </p>

          <p class="hidden lg:block lg:mb-7">
            Aponte a câmera do seu celular para o QR Code ao lado <br />
            ou se preferir, clique em um dos botões abaixo e baixe nosso
            aplicativo
          </p>
          <p class="hidden lg:block">
            Depois, siga as instruções para abrir sua conta. É fácil e rápido.
          </p>
        </div>
        <div
          class="
            download-options
            flex flex-col
            items-center
            mt-6
            lg:mt-8
            justify-center
            lg:justify-start
            lg:flex-row
          "
        >
          <a
            href="https://apps.apple.com/br/app/otto-finan%C3%A7as/id1207689926"
            aria-label="Página de download do aplicativo do Otto para IOS"
            target="_blank"
            class="lg:mr-7 mb-5 lg:mb-0"
          >
            <img
              width="768"
              height="252"
              src="../assets/imgs/download-app-store.webp"
              alt="App store link"
              class="w-56"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.ottoapp"
            aria-label="Página de download do aplicativo do Otto para Android"
            target="_blank"
          >
            <img
              width="764"
              height="252"
              src="../assets/imgs/download-play-store.webp"
              alt="Play store link"
              class="w-56"
            />
          </a>
        </div>
        <div class="register-web mt-13 hidden">
          <a
            href="https://app.ottofinancas.com.br/cadastro"
            aria-label="Página de cadastro no Otto pelo navegador"
            class="text-blueOtto-100 flex"
          >
            Cadastre-se online
            <img
              src="../assets/svgs/arrow-right-blue.svg"
              alt="seta"
              class="ml-2 w-3"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import QRCodeViewer from "@/components/QRCodeViewer.vue";
import BaseButton from "@/components/BaseButton.vue";

@Options({
  components: {
    QRCodeViewer,
    BaseButton,
  },
})
export default class DownloadAppInstructions extends Vue {}
</script>

<style scoped>
section#download-app-instructions {
  background: linear-gradient(
    260.51deg,
    #ebf1fe 3.87%,
    rgba(235, 241, 254, 0) 100%
  );
}

@media (min-width: 960px) {
  .lg-download {
    height: calc(100vh - 88px);
  }
}

a {
  transition: opacity 0.2s ease-in-out;
}

a:hover {
  opacity: 0.5;
}
</style>
